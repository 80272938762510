import {FC} from 'react';
import {Box} from '@mui/material';
import {Svg} from 'modules/theme/components/svg/Svg';
import ProfiClient from 'modules/theme/components/assets/badges/svg/ProfiClient.svg';

export const ProfiBadge: FC = () => {
    return <Box>
        <Svg component={ProfiClient} width={40} height={40} />
    </Box>;
};
